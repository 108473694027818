import React from 'react';
import { graphql } from 'gatsby';
import { FlowToContact } from '../components/global/FlowToContact';
import { PlatformHeader } from '../components/platform/PlatformHeader';
import { FeatureSections } from '../components/global/FeatureSections';
import { Container, Row, Col } from 'react-bootstrap';
import { Specifications } from '../components/global/Specifications';
import { UseCases } from '../components/global/UseCases';
import { StaticImage } from 'gatsby-plugin-image';
import { HeadElement } from '../components/global/HeadElement';

function Platform({ data }) {
   const { contentFeatures, contentSpecs, useCases } = data;

   const contentBlock1 = contentFeatures.edges.filter((element) => element.node.frontmatter.group === 1);
   const contentBlock2 = contentFeatures.edges.filter((element) => element.node.frontmatter.group === 2);

   return (
      <div>
         <HeadElement namespace="platform" />
         <Container>
            <PlatformHeader />
            <Row>
               <Col sm="12" md="6" lg="4">
                  <StaticImage
                     src="../images/platform/tablet_compressed.png"
                     imgClassName="image-style image-style-platform"
                     className="d-block w-100"
                  />
               </Col>
               <Col sm="6" lg="4" className="d-none d-md-block">
                  <StaticImage
                     src="../images/platform/laptop_compressed.png"
                     imgClassName="image-style image-style-platform"
                     className="d-block w-100"
                  />
               </Col>
               <Col sm="6" lg="4" className="d-none d-lg-block">
                  <StaticImage
                     src="../images/platform/phone_compressed.png"
                     imgClassName="image-style image-style-platform"
                     className="d-block w-100"
                  />
               </Col>
            </Row>
         </Container>
         <FeatureSections variant="vture-dark-cyan" content={contentBlock1} />
         <Container>
            <UseCases content={useCases} />
         </Container>
         <FeatureSections variant="vture-dark-cyan" content={contentBlock2} />
         <Container>
            <Specifications content={contentSpecs} />
            <FlowToContact variant="vture-dark-cyan" />
         </Container>
      </div>
   );
}

export default Platform;

export const query = graphql`
   query ($language: String!) {
      locales: allLocale(filter: { language: { eq: $language } }) {
         edges {
            node {
               ns
               data
               language
            }
         }
      }
      contentFeatures: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/platform" }, type: { eq: "features" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  image {
                     childImageSharp {
                        gatsbyImageData(width: 1200, placeholder: BLURRED)
                     }
                  }
                  imageAlt
                  isHeader
                  icon
                  order
                  group
                  language
               }
            }
         }
      }
      useCases: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/platform" }, type: { eq: "usecases" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  isHeader
                  order
                  title
                  language
               }
            }
         }
      }
      contentSpecs: allMarkdownRemark(
         filter: { frontmatter: { route: { eq: "/platform" }, type: { eq: "specs" }, language: { eq: $language } } }
         sort: { fields: frontmatter___order, order: ASC }
      ) {
         edges {
            node {
               id
               html
               frontmatter {
                  isHeader
                  order
                  language
               }
            }
         }
      }
   }
`;
